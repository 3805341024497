// Here you can add other styles
#accordion .card {
  margin: 15px 0;
}

.custom-switch-pad {
  padding-top: 8px !important;
}

.custom-media-margin-5 {
  margin: 5px;
}

.custom-checkbox-size-20 {
  width: 15px;
  height: 15px;
  margin-top: 15px;
  margin-left: -28px !important;
}

.padding-0 {
  padding: 0 !important;
}

.margin-5 {
  margin: 5px;
}

.clear-both {
  clear: both;
}
.form-disable {
  pointer-events: none;
  opacity: 0.4;
}
.form-enable {
  pointer-events: initial;
  opacity: 1;
}

.c-arrow-down-rotate {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%238a93a2' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
  display: block;
  width: 20px;
  height: 20px;
  content: "";
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12.5px;
  transition: 0.5s;
  transform: rotate(270deg);
}
.c-arrow-down {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%238a93a2' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
  display: block;
  width: 20px;
  height: 20px;
  content: "";
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12.5px;
  transition: 0.5s;
  transform: rotate(90deg);
}

#accordion > .card > .card-header {
  margin-bottom: 0 !important;
}

.form-group {
  margin-bottom: 0.5rem !important;
}
label {
  font-weight: 500 !important;
}

.br-color-light {
  border-color: #ccccd07a;
}

.btn-disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.c-switch-slider {
  background-color: #c1c1c1;
}
.react-datepicker-wrapper input[type="text"] {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid;
  color: #768192;
  background-color: #fff;
  border-color: #d8dbe0;
  border-radius: 0.25rem;
}
